import { UseFormReturn } from 'react-hook-form';

export function isFormInvalid(form: UseFormReturn): boolean {
  /**
   * form.isValid isn't good because it's updated 'onChange', ignoring the validation settings
   * therefore we need to base it on the errors object
   */
  const isInvalid = Object.keys(form.formState.errors).length > 0;
  return isInvalid;
}
